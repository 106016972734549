import moment from "moment";
import { I18n } from "sweet-i18n";

let i18n: I18n = null!;
let lastSchema: string = '';

export type Language = 'se' | 'no' | 'us';

/**
 * Updates text translations.
 * @param schema Document with translations of phrases.
 */
export function setI18nSchema(schema: string) {
  lastSchema = schema;
  i18n = new I18n(getLanguage(), schema, 'se');
}

setI18nSchema(''); // Temporary until a new schema is loaded.

const preferredLanguage = localStorage.getItem('language');
if (preferredLanguage) {
  i18n.language = preferredLanguage;
}

/**
   * Translates a phrase. If a matching translation is missing, then the original phrase is returned.
 */
export function text(phraseOrKey: string | TemplateStringsArray, ...args: any[]) {
  return i18n.get(phraseOrKey, ...args);
}

/**
 * Returns the current language of translated texts.
 */
export function getLanguage(): Language {
  return i18n?.language as Language ?? "se";
}

/**
 * Sets the language of translated texts.
 */
export function setLanguage(language: Language) {
  if (language) {
    i18n.language = language;
  } else {
    language = getLanguage();
  }

  //  HTML lang codes: https://www.w3schools.com/tags/ref_language_codes.asp
  if (language === 'se') {
    document.querySelector('html').lang = 'sv';
    moment.locale('sv');
    require('moment/locale/sv');

  } else if (language === 'no') {
    document.querySelector('html').lang = 'nb';
    moment.locale('nb');
    require('moment/locale/nb');

  } else {
    document.querySelector('html').lang = 'en';
    moment.locale('en-gb');
    require('moment/locale/en-gb');

  }
}

export function getSchema() {
  return lastSchema;
}

export function getI18n() {
  return i18n;
}