import moment from "moment";
import React, { useState } from "react";
import { Button } from "../Button/Button";
import { BasicPopover, PopoverProps } from "../popover";
import { TooltipTrigger } from "../Tooltip/TooltipTrigger";
import { TransitionedContainer } from "../transitioned_container";
import { DateList, DateListProps } from "./DateList";
import { TimeSelect } from "../../calendar/calendar/CalendarEventModal/TimeSelect/TimeSelect";
import FontAwesomeIcon from "../fa_icon";
import { SectionTransitionedContainer } from "../SectionTransitionedContainer/SectionTransitionedContainer";
import { text } from "../../../utils/i18n";

type BasicProps = {
  value: Date | null;
  time?: boolean;
  onChange: (value: Date | null) => void;
} & Pick<DateListProps, 'validation' | 'isClearable'>

type Props = BasicProps & PopoverProps;

/**
 * Popover for selecting a date.
 */
export const DatePopover: React.FC<Props> = ({
  value, onChange, validation,
  time = false, isClearable = false, ...popoverProps
}) => {
  const [viewedDate, setViewedDate] = useState<Date>(value || new Date());

  return (
    <BasicPopover
      positionCenterOf={window}
      verticalPosition="bottom"
      closeButton
      title={(
        <DatePopoverHeader
          viewedDate={viewedDate}
          onViewedDateChange={date => {
            setViewedDate(date);
          }}
          isClearable={isClearable} />
      )}
      {...popoverProps}>
      <DatePopoverDateList
        onChange={date => {
          if (time && !value) {
            //  Default time is now.
            const now = new Date();
            date.setHours(now.getHours());
            date.setMinutes(now.getMinutes());
          }

          onChange(date);
        }}
        value={value}
        viewedDate={viewedDate}
        isClearable={isClearable}
        validation={validation}
      />

      {
        time ? (
          <TransitionedContainer
            style={{ width: '100%' }}
            transitionContainerStyle={{ width: '100%' }}
            lock={Boolean(value)}>
            {
              value ? (
                //  Must select a date first. Not sure how to show the time in a good way without a value.
                <div
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8, width: '50%' }}>
                  <FontAwesomeIcon name="clock" type="regular" />
                  <TimeSelect
                    containerStyle={{ flexGrow: 1 }}
                    value={value}
                    onChange={({ hour, minute }) => {
                      onChange(moment(value || new Date()).hour(hour).minute(minute).toDate());
                    }}
                    inputStyle={{ fontSize: "inherit" }}
                  />
                </div>
              ) : (
                <div></div>
              )
            }
          </TransitionedContainer>
        ) : null
      }
    </BasicPopover >
  )
}

type DatePopoverHeaderProps = {
  viewedDate: Date;
  onViewedDateChange: (viewedDate: Date) => void;
  isClearable?: boolean;
}

export const DatePopoverHeader: React.FC<DatePopoverHeaderProps> = ({
  onViewedDateChange, viewedDate, isClearable = false
}) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 8,
    }}>
      <TooltipTrigger tooltip={text`Förgående månad`}>
        <Button
          icon="chevron-left"
          onClick={event => {
            onViewedDateChange(moment(viewedDate).subtract(1, 'month').toDate());

            event.stopPropagation();
            event.preventDefault();
          }}
        />
      </TooltipTrigger>
      <div style={{ width: isClearable ? 64 : 108, textAlign: 'center', whiteSpace: 'nowrap' }}>
        {
          //  If clearable, leave more room for a clear button.
          moment(viewedDate).startOf('day')
            .format(isClearable ? 'MMM YYYY' : 'MMMM YYYY')
        }
      </div>
      <TooltipTrigger tooltip={text`Nästa månad`}>
        <Button
          icon="chevron-right"
          onClick={event => {
            onViewedDateChange(moment(viewedDate).add(1, 'month').toDate());

            event.stopPropagation();
            event.preventDefault();
          }}
        />
      </TooltipTrigger>
    </div>
  );
}

type DatePopoverDateListProps = BasicProps & {
  viewedDate: Date;
};

export const DatePopoverDateList: React.FC<DatePopoverDateListProps> = ({
  onChange,
  value,
  viewedDate,
  isClearable,
  validation
}) => {
  isClearable = isClearable ?? false;

  const viewedMonth = viewedDate.getMonth();
  const viewedYear = viewedDate.getFullYear();

  return (
    <SectionTransitionedContainer
      props={{
        style: { width: 42 * 7 },
        transitionContainerStyle: { width: '100%' }
      }}
      section={viewedYear * 12 + viewedDate.getMonth()}>
      <DateList
        month={viewedMonth}
        year={viewedYear}
        value={value}
        onChange={onChange}
        isClearable={isClearable}
        validation={validation}
      />
    </SectionTransitionedContainer>
  )
}