import { createTemporaryId, isCreated } from '../utils/model';
import { BasicFieldReference } from './BasicField';

export type ProductImage = {
  uri: string;
  mimetype: string;
};

export enum InputType {
  None = 'none',
  Quantity = 'quantity',
  Price = 'price'
}

/**
 * A single product.
 */
export default class Product {
  readonly id: string = createTemporaryId();
  readonly key: string = createTemporaryId();
  readonly name: string = '';
  readonly price: number = 0;
  readonly salaryBasis: number = null;
  readonly categoryId: string = '';
  readonly image?: ProductImage;
  readonly fields: BasicFieldReference[] = [];
  readonly vat: number = 25;
  readonly createdAt: Date = new Date();
  /**
   * When selecting this product, this determines what information the user set.
   */
  readonly inputType: InputType = InputType.None;

  /**
   * Whether to list this product in express flows, presuming the express flow
   * is configured to show the product's category.
   */
  readonly isVisibleInExpressFlows: boolean = true;
  readonly isVisibleInWebForms: boolean = true;
  private readonly fieldValues: { [fieldId: string]: string } = {}

  constructor(deriveFrom?: Partial<Product>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }

    this.createdAt = new Date(this.createdAt);

    if (this.fields) {
      this.fieldValues = this.fields.reduce((object, { fieldId, value }) => {
        return { ...object, [fieldId]: value };
      }, {});
    }
  }

  get isCreated() {
    return isCreated(this.id);
  }

  get salaryBasedPrice() {
    return this.salaryBasis || this.price;
  }

  getFieldValue(productFieldId: string): string | null {
    return this.fieldValues[productFieldId] || null;
  }
}