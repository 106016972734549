import React, { useEffect, useRef } from "react";
import { TransitionType, TransitionedContainer, TransitionedContainerProps } from "../transitioned_container";

type Props = {
  section: string | number;
  vertical?: boolean;
  props?: Partial<TransitionedContainerProps>;
}

/**
 * Wraps children with a TransitionedContainer. The transition is set depending on the value of `section`
 * compared with the previous value.
 */
export const SectionTransitionedContainer: React.FC<Props> = ({ section, vertical, children, props }) => {
  const previousSection = useRef(section);
  const transition = useRef<TransitionType>(vertical ? "slide-up" : "slide-right");

  if (section !== previousSection.current) {
    //  useEffect was not as reliable.
    transition.current = Number(section) > Number(previousSection.current) ?
      (vertical ? "slide-down" : "slide-left") : (vertical ? "slide-up" : "slide-right");
    previousSection.current = section;
  }

  return (
    <TransitionedContainer
      style={{ width: '100%' }}
      transitionContainerStyle={{ width: '100%' }}
      transition={transition.current}
      lock={section}
      transitionWidth={false}
      transitionHeight
      {...props}>
      {children}
    </TransitionedContainer>
  )
}