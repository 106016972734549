import { apis } from "../../backend";
import Issuer from "../../models/Issuer";

export type OfferFromToken = { offerId: string; serialId: number; issuer: Issuer; }

export async function getOfferFromToken(token: string, pin: string): Promise<OfferFromToken> {
  return await apis.offers.get('/offer-document-link-v1', { token, pin });
}

export async function getOfferDocument(token: string, pin: string): Promise<Blob> {
  const url = apis.offers.getUrl('/offer-document-link-v1/document', { token, pin });

  const response = await fetch(url);
  if (!response.ok) {
    throw new Error("Error getting document: " + await response.text());
  }

  return await response.blob();
}

export async function addEmailNotificationRecipient(token: string, pin: string, email: string) {
  return await apis.offers.post('/offer-document-link-v1/email', { email }, { token, pin });
}