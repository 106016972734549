import { sleep } from "../utils";

type RetryOptions = {
  /**
   * Number of attempts.
   * @default 3
   */
  attempts?: number;

  /**
   * Time in milliseconds to sleep between each attempt.
   * @default 1500
   */
  sleep?: number;

  /**
   * Allows for stopping.
   */
  signal?: AbortSignal;

  /**
   * If true, then an error will be thrown on `signal` abort.
   * Otherwise, the function will return undefined.
   */
  errorOnAbort?: boolean;
};

/**
 * Retries awaiting `work` `opts.attempts` times.
 * If the last attempt fails, then the most recent error is thrown.
 * 
 * @returns The result, or undefined if aborted.
 */
export async function retry<T>(work: () => Promise<T>, opts?: RetryOptions): Promise<T | undefined> {
  const attempts = opts?.attempts ?? 3;

  let error: any;
  for (let i = 0; i < attempts; i++) {
    if (opts?.signal?.aborted && opts?.errorOnAbort) {
      throw new Error("Aborted");
      
    } else if (opts?.signal?.aborted) {
      return undefined;
    }

    try {
      return await work();
    } catch (_error) {
      error = _error;
      console.error(_error);
      await sleep(opts?.sleep ?? 1500);
    }
  }

  throw error;
}