import ProductCategory from "./ProductCategory";
import Product from "./Product";
import { excludingVat } from "../utils/vat";
import roundTo from "round-to";
import { BasicFieldReference } from "./BasicField";
import { text } from "../utils/i18n";

/**
 * Represents a reference to a product and its containing category at some point in time.
 */
export class ProductReference {
  static fromProduct(product: Product, category?: ProductCategory): ProductReference {
    return new ProductReference({
      categoryId: product.categoryId,
      categoryName: category?.name || text`Exempelkategori`,
      price: product.price,
      productId: product.id,
      productName: product.name,
      quantity: 1,
      salaryBasis: product.salaryBasis,
      vat: product.vat,
    });
  }

  readonly productId: string;
  readonly productName: string;
  readonly categoryId: string;
  readonly categoryName: string;
  readonly quantity: number;
  readonly price: number;
  readonly salaryBasis?: number;
  readonly vat: number;
  readonly fields?: BasicFieldReference[];

  constructor(deriveFrom?: Partial<ProductReference>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }

  /**
   * @returns A basic, listable product category.
   */
  toProductCategory() {
    return new ProductCategory({
      id: this.categoryId,
      name: this.categoryName,
    });
  }

  /**
   * @returns A basic, listable product.
   */
  toProduct() {
    return new Product({
      id: this.productId,
      key: this.categoryId,
      categoryId: this.categoryId,
      name: this.productName,
      salaryBasis: this.salaryBasis,
      price: this.price,
      vat: this.vat
    });
  }
}

export type ProductTotal = {
  /**
   * Price total, including VAT.
   */
  price: number;

  /**
   * Unit price total, including VAT.
   */
  unitPrice: number;

  /**
   * Price total, excluding VAT.
   */
  priceExcludingVat: number;

  /**
   * Unit price total, excluding VAT.
   */
  unitPriceExcludingVat: number;

  /**
   * VAT total.
   */
  vat: number;

  /**
   * Unit VAT total.
   */
  unitVat: number;
}

export function getProductTotal(productReferences: ProductReference[]): ProductTotal {
  let total: ProductTotal = {
    price: 0,
    unitPrice: 0,
    priceExcludingVat: 0,
    unitPriceExcludingVat: 0,
    vat: 0,
    unitVat: 0
  };

  for (const product of productReferences) {
    const quantity = product.quantity ?? 1;
    const vat = product.vat ?? 0;

    total.priceExcludingVat += roundTo(excludingVat(product.price * quantity, vat), 2);
    total.unitPriceExcludingVat += roundTo(excludingVat(product.price, vat), 2);

    total.unitPrice += product.price;
    total.price += product.price * quantity;

    total.unitVat += roundTo(product.price - excludingVat(product.price, vat), 2)
    total.vat += roundTo((product.price * quantity) - excludingVat((product.price * quantity), vat), 2);
  }

  return total;
}