import PhoneNumber from "./PhoneNumber";

export enum BuiltInCallUtility {
  CreateOrder = 'createOrder',
  CreateOffer = 'createOffer',
}

/**
 * These properties will not default to another value if empty, in merge().
 */
const emptyableProperties: (keyof UserPreferences)[] = [
  'enabledCallUtilities'
];

/**
 * Arbitrary, personal user preferences.
 */
export default class UserPreferences {
  readonly offerDefaultEmailSubject?: string;
  readonly offerDefaultEmailMessage?: string;
  readonly offerDefaultSmsMessage?: string;

  // The default value is true, but allow passing a value of `undefined` to the constructor, to reset to organization default.
  readonly includeVatInSum: boolean = true;

  /**
   * Can be null if unset.
   * If null, then the user may be prompted to enable or disable.
   */
  readonly callConnectNotificationEnabled?: boolean;
  readonly callerPhoneNumbers?: string[];

  /**
   * Ordered list of enabled order list headings.
   */
  readonly enabledOrderListHeadingIds: string[];

  /**
   * Ordered list of enabled offer list headings.
   */
  readonly enabledOfferListHeadingIds: string[];

  /**
   * Ordered list of enabled call list headings.
   */
  readonly enabledCallListHeadingIds: string[];

  /**
   * Ordered list of enabled lead list list headings.
   */
  readonly enabledDialGroupListHeadingIds: string[];

  /**
   * Ordered list of enabled call list headings.
   */
  readonly enabledContactListHeadingIds: string[];

  /**
   * Admin only. Ordered list of enabled organization list headings.
   */
  readonly enabledOrganizationListHeadingIds: string[];

  /**
   * List of enabled built-in call utilities. Defaults to all.
   * 
   * Built- in call utilities can be disabled or hidden if there exists call actions
   * which replaces them.
   */
  readonly enabledCallUtilities: BuiltInCallUtility[];

  constructor(deriveFrom?: Partial<UserPreferences>) {
    if (!deriveFrom) {
      return;
    }

    Object.assign(this, deriveFrom);
  }

  static withDefaults(other: Partial<UserPreferences>) {
    return new UserPreferences(other).merge(defaults);
  }

  /**
   * Returns a new instance where this instance is merged with `other`.
   * This instance has precedence.
   * 
   * @param properties List of properties to include
   */
  merge(other: Partial<UserPreferences>, ...properties: (keyof UserPreferences)[]): UserPreferences {
    let result: Partial<UserPreferences> = { ...this };

    for (const property in other) {
      if (properties?.length && !properties.includes(property as keyof UserPreferences)) {
        continue;
      }

      const replacedValue = result[property];

      if (replacedValue === undefined ||
        replacedValue === null ||
        (!emptyableProperties.includes(property as keyof UserPreferences) &&
          Array.isArray(replacedValue) && !replacedValue.length)) {
        result[property] = other[property];
      }
    }

    return new UserPreferences(result);
  }

  /**
   * Returns phone numbers which are included in `callerPhoneNumbers`.
   * 
   * For each phone number, if the number has been renewed, then the **top-most recent**
   * phone number is returned, which originates from the number in `callerPhoneNumbers`.
   */
  listCallerPhoneNumbers(allPhoneNumbers: PhoneNumber[]): PhoneNumber[] {
    if (!this.callerPhoneNumbers) {
      return [];
    }

    return this.callerPhoneNumbers.reduce((phoneNumbers, callerPhoneNumber) => {
      const phoneNumber = allPhoneNumbers.find(phoneNumber =>
        phoneNumber.isUsable() && (
          phoneNumber.phoneNumber === callerPhoneNumber ||
          phoneNumber.replacedPhoneNumbers.includes(callerPhoneNumber)
        ));

      if (phoneNumber) {
        return [...phoneNumbers, phoneNumber];
      }

      return phoneNumbers;
    }, [] as PhoneNumber[]);
  }
}

//  Put default values here.
const defaults = new UserPreferences({
  enabledOrderListHeadingIds: ["user", "serialId", "sum", "date", "tags", "businessDate"],
  enabledOfferListHeadingIds: ["user", "serialId", "sum", "date", "status"],
  enabledCallListHeadingIds: ["user", "serialId", "date", "recipient", "tags"],
  enabledDialGroupListHeadingIds: ["name", "serialId", "date", "status"],
  enabledContactListHeadingIds: ["user", "serialId", "date", "tags"],
  enabledCallUtilities: Object.values(BuiltInCallUtility),

  //  Admin only.
  enabledOrganizationListHeadingIds: ["name", "identityNumber", "createdAt"],
});

export function getOrderFieldHeadingId(fieldId: string) {
  return "order-field-" + fieldId;
}

export function getContactFieldHeadingId(fieldId: string) {
  return "contact-field-" + fieldId;
}