import User from "./User";

/**
 * Base visibility.
 */
export class Visibility {
  readonly teamIds: string[] = [];

  constructor(deriveFrom?: Partial<Visibility>) {
    if (deriveFrom) {
      Object.assign(this, deriveFrom);
    }
  }

  isVisible(currentUser: User) {
    if (currentUser.isAdmin) {
      return true;
    }

    if (currentUser.teamId && this.teamIds.includes(currentUser.teamId)) {
      return true;
    }

    if (currentUser.supervisedTeamIds.find(teamId => this.teamIds.includes(teamId))) {
      return true;
    }

    return false
  }
}